.HeaderBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(
    to right,
    var(--editor-background-color),
    var(--stack-vis-background-color)
  );
  height: 56px;
  box-shadow: inset 0 -7px 9px -7px rgba(23, 25, 27, 0.5);

  .left-section,
  .right-section {
    display: flex;
    align-items: center;
  }

  .app-title {
    color: #fff8;
    font-size: 16px;
    font-weight: normal;
    margin: 13px;
    font-family: var(--monospace-font-family);
    .bitauth {
      padding-right: 10px;
      border-right: 1px solid #fff3;
      margin-right: 10px;
    }
  }

  a.link,
  button.link {
    background: none;
    -webkit-appearance: none;
    border: none;
    line-height: 0;
    color: #fff;
    font-size: 12px;
    padding: 0;
    margin-left: 24px;
    display: flex;
    align-items: center;
    &:hover {
      color: #fff;
    }
  }

  .left-section .bp4-popover-wrapper {
    line-height: 0 !important;
  }

  .github-logo {
    img {
      width: 14px;
      margin-right: 6px;
    }
  }
  .link {
    .bp4-icon {
      margin-right: 6px;
    }
  }

  .bp4-button {
    background: transparent none !important;
    box-shadow: none !important;
    color: #fff8 !important;
    .bp4-icon-caret-down {
      color: #242b2f !important;
    }
  }
}

.intro-popover {
  $tooltip-background: var(--interface-background-color);
  .bp4-popover-content {
    max-width: 400px !important;
    background: $tooltip-background !important;
    color: #fff !important;
    padding: 12px;
  }
  .bp4-popover-arrow-fill {
    fill: $tooltip-background !important;
  }
  p {
    margin: 0;
    font-size: 16px;
  }
}
