.ScriptEditor {
  background: var(--editor-background-color);

  $headerHeight: 14;
  $headerPadding: 8;
  $headerVertical: $headerHeight + ($headerPadding * 2) + 4;

  h2 {
    font-weight: normal;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: #{$headerPadding}px 14px;
    background: var(--interface-background-color);
    height: #{$headerHeight}px;
    box-sizing: initial;

    /**
     * Hover over the editor, which is shifted upward to compensate for adding top-margin using Monaco view zones. 
     */
    z-index: 1;
    position: relative;

    .icon {
      color: var(--toolbar-icon-color);
      vertical-align: middle;
      margin-right: 10px;
    }

    .icon,
    .bp4-icon {
      &:focus {
        outline: none;
        color: #fff;
      }
    }

    .script-buttons {
      flex-grow: 1;
      text-align: right;
      line-height: 0;
      color: var(--toolbar-icon-color);
      .bp4-icon:hover {
        cursor: pointer;
        color: #fff;
      }
    }
  }

  .script-tag {
    font-size: 0.7em;
    background-color: #2e7105;
    border-radius: 3px;
    display: inline-block;
    padding: 0.1em 0.5em;
    margin-left: 1em;
  }

  .p2sh-tag {
    text-transform: uppercase;
    background-color: #2e7105;
  }

  .pushed-tag {
    // text-transform: uppercase;
    background-color: #c5872b;
  }

  .editor {
    height: calc(100% - #{$headerVertical}px);
  }

  .monaco-editor {
    .scroll-decoration {
      box-shadow: var(--scrolled-under-shadow);
    }
    .monaco-icon-label::before {
      margin-right: 4px;
    }

    .unexecuted-sample {
      opacity: 0.3;
      filter: grayscale(1);
    }

    /**
     * Hide the status-bar/actions from the hover tooltip. (Hides the "Peek
     * Problem" and "No quick fixes available" when hovering over an error.)
     */
    .monaco-editor-hover .hover-row.status-bar {
      display: none;
    }
  }
}
