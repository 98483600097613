html,
body,
#root {
  height: 100%;
  width: 100%;
}

html {
  background-color: var(--interface-background-color);
}

body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

:root {
  --standard-font-family: 'Fira Sans', sans-serif;
  --monospace-font-family: 'Fira Mono', monospace;
  --interface-background-color: #24282a;
  --sidebar-background-color: var(--interface-background-color);
  --sidebar-highlight-color: rgba(131, 226, 252, 0.2);
  --editor-background-color: #1d2023;
  --stack-vis-background-color: #181b1e;
  --scrolled-under-shadow: #111a 0 6px 6px -6px inset;
  --toolbar-icon-color: #fff9;
}

* {
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }
}

/**
 * A visible outline color for all browsers.
 */
:focus,
::-moz-focus-inner,
:-moz-focusring,
:active {
  border-color: #8addff !important;
  outline-color: #8addff !important;
}
