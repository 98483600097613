.EntityVariableEditor {
  background-color: var(--stack-vis-background-color);

  h2 {
    background: var(--editor-background-color);
  }

  .entity-variables {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    overflow-y: auto;

    .variable,
    .add-variable {
      width: 600px;
      max-width: 80%;
      margin: 12px 0;
      padding: 0;
      border-radius: 5px;
      background: var(--editor-background-color);
    }

    .description {
      padding: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }

    .add-variable {
      background: none transparent;
      border: 2px #fff1 dashed;
      text-align: center;
      padding: 12px;
      cursor: pointer;
    }

    .variable-header {
      font-weight: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 12px;
      border-bottom: 1px solid var(--stack-vis-background-color);

      .bp4-icon {
        color: #fff6;
        margin-right: 12px;
        margin-bottom: 2px;
      }

      .identifier {
        font-family: var(--monospace-font-family);
        background: var(--stack-vis-background-color);
        border: 1px solid var(--interface-background-color);
        border-radius: 2px;
        padding: 2px 6px;
      }
    }
  }
}
