.ProjectExplorer {
  overflow-y: auto;

  .script-section,
  .entities-section {
    &:hover .add-button,
    .add-button:focus {
      opacity: 1;
    }
  }

  .add-button {
    -webkit-appearance: none;
    border: 0;
    background: 0 none;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-align: inherit;
    opacity: 0;
    transition: opacity 0.2s;
    display: inline-block;
    line-height: 0;
    vertical-align: middle;
    margin: 0 0 2px 6px;
    padding: 0;
  }

  .no-button-style {
    -webkit-appearance: none;
    border: 0;
    background: 0 none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    color: inherit;
    text-align: inherit;
    cursor: pointer;

    &:focus,
    &.activatable:hover {
      outline: none;
      li {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &:active > li {
      border-left: 2px solid #8addff;
    }

    &.activatable {
      pointer-events: all;
    }

    &.active {
      cursor: default;
      pointer-events: none;

      .title-area,
      & > li {
        border-left-color: #2a5b8b;
        background-color: transparent !important;
      }
    }
  }

  .title-area {
    font-size: 1em;
    padding: 1em;
    margin: 0;
    border-bottom: 1px solid var(--editor-background-color);
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      flex-grow: 1;
    }

    .settings-button {
      color: #fff9;
      line-height: 0;
      margin-left: 5px;
    }
  }

  h3 {
    font-size: 16px;
    color: #868f98;
    font-weight: normal;
    text-transform: uppercase;
    padding-left: 1em;
  }

  ul {
    padding: 0;
    margin-top: 8px;
  }

  & > ul {
    margin: 0 0 2em 0;
    ul {
      margin-top: 0.7em;
    }
  }

  .title-area,
  li {
    border-left: 2px solid transparent;
  }

  li {
    list-style: none;
    padding: 0.5em 1em;

    .icon {
      color: #fff4;
      vertical-align: middle;
      margin-right: 6px;
      outline: none;
      pointer-events: all;
    }
  }
}

.interface-tooltip {
  $tooltip-background: var(--editor-background-color);
  .bp4-popover-content {
    background: $tooltip-background !important;
    color: #fff !important;
  }
  .bp4-popover-arrow-fill,
  .bp4-popover-arrow-border {
    fill: $tooltip-background !important;
    fill-opacity: 1;
  }
}
