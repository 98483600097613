.editor-dialog,
.bp4-dialog,
.bp4-dark.editor-dialog,
.bp4-dark .editor-dialog {
  font-family: var(--standard-font-family);
  color: #fffc;
  background: var(--editor-background-color);

  .bp4-dialog-header {
    background: var(--interface-background-color);
    .bp4-icon {
      color: #fff6;
    }
  }
  .bp4-heading {
    color: #fff8;
    font-weight: normal;
    font-size: 14px;
  }

  .divider {
    width: 100%;
    height: 0;
    border-bottom: 2px dashed #fff1;
    margin: 24px 0;
  }

  select,
  .bp4-input,
  .bp4-button {
    background: var(--interface-background-color);
    color: #fffc;
    &:hover {
      background: #273136;
    }
    &.bp4-disabled {
      background: var(--interface-background-color);
      color: #fff3;
      &:hover {
        background: var(--interface-background-color);
      }
    }
    &.bp4-intent-danger {
      background-color: #db3737;
      &:hover {
        background-color: #c23030;
      }
    }
  }

  code {
    background-color: var(--stack-vis-background-color);
    border-radius: 2px;
    margin: 0 2px;
    padding: 0 2px;
    border: 1px solid var(--interface-background-color);
  }

  .bp4-label {
    width: 90px;
    flex-shrink: 0;
  }

  .bp4-dialog-footer-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bp4-button {
    flex-shrink: 0;
  }

  .error {
    display: flex;
    align-items: center;

    .bp4-icon {
      margin-right: 6px;
      margin-bottom: 2px;
    }
    code {
      padding: 2px 4px;
      margin: 0 4px;
    }
  }
}

/**
 * The dialog container itself may be outlined when no internal elements are
 * outlined (adding a thin border around the full window). This is useless and
 * distracting, so we hide it.
 */
.bp4-dialog-container {
  outline: none;
}
