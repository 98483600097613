.TemplateSettings {
  background-color: var(--editor-background-color);

  &.EditorPane {
    overflow-y: auto;
    .EditorPaneContents {
      overflow-y: initial;
    }
  }

  .name {
    font-size: 20px;
    margin: 10px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .description {
    font-size: 14px;
    max-width: 700px;
    pre {
      font-family: var(--monospace-font-family);
      white-space: pre-wrap;
      word-break: break-word;
      margin: 0;
    }
  }

  .template-settings {
    max-width: 1200px;
  }

  .instructions {
    margin: 2em 0;
    font-style: italic;
    font-weight: bold;
  }

  .bp4-checkbox {
    display: flex;
    align-items: center;
  }

  .bp4-editable-text::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  }

  .supported-vms-label {
    max-width: 350px;
    margin-right: 24px;
    display: block;
    line-height: 1;
    h4 {
      margin: 0 24px 12px 0;
    }
    p {
      color: #5c7080;
      line-height: 1.3;
    }
  }

  .vm {
    display: flex;
    align-items: center;
    color: #fff5;
    code {
      color: #fff;
      background-color: var(--stack-vis-background-color);
      border-radius: 2px;
      margin: 0 4px;
      padding: 2px 4px;
      border: 1px solid var(--interface-background-color);
    }
    .chain {
      color: #fff;
    }

    .version {
      margin: 0 6px;
    }

    .tag {
      display: inline-block;
      padding: 1px 3px;
      border-radius: 4px;
      font-size: 10px;
      text-transform: lowercase;
      margin-bottom: 2px;

      &.spec {
        background-color: #c5872b;
        color: #fff;
      }
      &.live {
        background-color: #0d540d;
        color: #fff;
      }
    }
  }

  .divider {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #fff1;
    margin: 36px 0;
  }
}
