.EntitySettingsEditor {
  background-color: var(--editor-background-color);
  h2 {
    background: var(--interface-background-color);
  }

  .name {
    margin: 0 0 12px;
  }

  code {
    background-color: var(--stack-vis-background-color);
    border-radius: 2px;
    margin: 0 2px;
    padding: 0 2px;
    border: 1px solid var(--interface-background-color);
  }

  .bp4-input {
    background: var(--stack-vis-background-color);
  }
  .bp4-label {
    width: 90px;
    flex-shrink: 0;
  }
  .bp4-form-group .bp4-form-helper-text {
    color: #fff6;
    line-height: 18px;
  }
}
