.ImportExportDialog {
  max-width: 900px;
  width: 100% !important;

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .actions-right {
    display: flex;
  }
  .action {
    margin: 12px 12px 24px 0;
  }
  .import-input {
    .bp4-file-upload-input::after {
      background: var(--interface-background-color);
    }
  }
  .error {
    code {
      padding: 8px 16px;
      margin: 0 16px;
      display: inline-block;
      line-height: 20px;
    }
  }
}

.import-export-editor {
  height: 400px;
}
.restore-dialog {
  width: 500px;
  max-width: 500px;
}
.share-dialog {
  width: 600px;
  max-width: 600px;
  .sharing-link {
    padding: 0.5em;
    width: 550px;
    display: block;
    background: var(--interface-background-color);
    color: #fffc;
    border: 0 none;
  }
}
.show-next-issue-button {
  cursor: pointer;
  color: #3c9dda;
  margin-left: 6px;
}

.sharing-link-popover {
  $tooltip-background: var(--stack-vis-background-color);
  .bp4-popover-content {
    max-width: 400px !important;
    overflow-wrap: break-word;
    background: $tooltip-background !important;
    color: #fff !important;
    padding: 12px;
  }
  .bp4-popover-arrow-fill {
    fill: $tooltip-background !important;
  }
}
