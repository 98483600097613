.EditVariableDialog {
  width: 700px;
  .name {
    display: flex;
    align-items: center;
    .bp4-icon {
      color: #fff3;
      margin-right: 12px;
      margin-bottom: 4px;
    }
  }
  .bp4-form-content {
    min-width: 0;
  }
  .result {
    overflow-wrap: break-word;
    display: block;
  }
  .guide-link {
    color: #106ba3;
    background: none;
    border: none;
    text-decoration: none;
    appearance: none;
    font-weight: bold;
    padding: 0;
    cursor: pointer;
  }
}
