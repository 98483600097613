.GuideDialog {
  width: 900px; // TODO: break up content, widen dialog, implement sidebar navigation
  padding: 2em 6em 3em;
  font-size: initial;

  // Scroll inside dialog to avoid blueprint bug in Firefox: https://github.com/palantir/blueprint/issues/1008
  height: 95vh;
  overflow: scroll;

  h2 {
    margin-top: 30px;
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  p,
  li {
    line-height: 1.5em;
  }
  em {
    font-weight: bold;
    font-style: normal;
  }
  li {
    margin-top: 10px;
  }
}
